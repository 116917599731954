import React from "react";
import {
  Grid,
  Box,
  Container,
  Typography,
  makeStyles,
  Button,
  ListItem,
  List,
  Link,
  IconButton,
} from "@material-ui/core";
import { useHistory, Link as RouterLink } from "react-router-dom";
import { AiOutlineMail } from "react-icons/ai";
import { FaFacebookF, FaInstagram, FaMediumM } from "react-icons/fa";
import { FiSend, FiTwitter } from "react-icons/fi";
import Scroll from "react-scroll";
const ScrollLink = Scroll.Link;

const useStyles = makeStyles((theme) => ({
  footerSection: {
    overflow: "hidden",
    backgroundSize: "cover",
    backgroundImage: "url(../images/comm_banner.png)",
    padding: "50px 0px 0",
    zIndex: "2",
    overflow: " hidden",
    "& .copy": {
      borderTop: "1px solid #525252",
      padding: "10px 0",
      textAlign: "center",
      fontWeight: 300,
      fontSize: "12px",
      color: "#626262",
    },
 
    "& ul": {
      paddingLeft: "0",
      [theme.breakpoints.down("sm")]: {
        marginBottom: "30px",
      },
      [theme.breakpoints.down("xs")]: {
        marginBottom: "0px",
      },
      "& li": {
        paddingLeft: "0",
        alignItems: "center",
        color: " #1D1D1D",
        fontSize: "14px",
        "& svg": {
          marginRight: "10px",
          color: "#408FAC",
          fontSize: "15px",
        },
      },
    },
    "& svg": {
      color: "#408FAC",
      fontSize: "15px",
    },
    "& p": {
      maxWidth: "400px",
      [theme.breakpoints.down("xs")]: {
        fontSize: "12px !important", 
      },
    },
    "& h6": {
      fontWeight: "400", 
      fontSize: "20px", 
      textTransform: "uppercase",
      [theme.breakpoints.down("sm")]: {
        marginTop: "30px",
      },
      [theme.breakpoints.down("xs")]: {
        marginTop: "20px",
        fontSize: "14px !important", 
      },
    },
    "& a": {
      color: "#fff", 
      display: "flex", 
      fontSize: "14px", 
      alignItems: "center", 
      fontWeight: "300", 
      textDecoration: "none",
      "&:hover": {
        color: "#35a5f5",
        textDecoration: "none",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "12px", 
      },
    },
  },
}));

export default function Liquidity() {
  const classes = useStyles();
  const history = useHistory();
  return (
    <>
      <Box className={classes.footerSection}>
        <Container maxWidth="lg">
          <Grid container>
            <Grid item xs={12} sm={6} md={6}>
              <Box>
                <Box mb={2}>
                  {" "}
                  <RouterLink to="/">
                    <img src="images/Logo.png" style={{ maxWidth: "150px", }} /> <br />
                  </RouterLink>
                </Box>
                <Typography variant="body2">We are the upcoming NFT Games & Metaverse market Place. We are creating a decentralized ecosystem.
                  </Typography>
                <Box mt={1}>
                  <Typography variant="body2">We are the upcoming NFT Games & Metaverse market Place. We are creating a decentralized ecosystem.
                  </Typography>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={4} sm={2}>
                  <Typography variant="h6">Project</Typography>
                  <List>
                    <ListItem to="/about" component={RouterLink}>
                    About
                    </ListItem>
                    <ListItem to="/" component={RouterLink}>
                    Whitepaper
                    </ListItem>
                    <ListItem to="/" component={RouterLink}>
                    PPT
                    </ListItem>
                    <ListItem to="/disclaimer" component={RouterLink}>
                    Disclaimer
                    </ListItem>
                    <ListItem to="/terms" component={RouterLink}>
                    Terms
                    </ListItem>
                  </List>
                </Grid>
                <Grid item xs={4} sm={2}>
                  <Typography variant="h6">Developers</Typography>
                  <List>
                    <ListItem to="/" component={RouterLink}>
                    Github
                    </ListItem>
                    <ListItem to="/" component={RouterLink}>
                    Audit
                    </ListItem>
                    <ListItem to="/" component={RouterLink}>
                    Articles
                    </ListItem>
                    <ListItem to="/" component={RouterLink}>
                    Smart Contract
                    </ListItem>
                  </List>
                </Grid>
                <Grid item xs={4} sm={2}>
                  <Typography variant="h6">Services</Typography>
                  <List>
                    <ListItem to="/" component={RouterLink}>
                    Dex
                    </ListItem>
                    <ListItem to="/" component={RouterLink}>
                    Launchpad
                    </ListItem>
                    <ListItem to="/" component={RouterLink}>
                    Stake
                    </ListItem>
                    <ListItem to="/" component={RouterLink}>
                    NFT
                    </ListItem>
                    <ListItem to="/" component={RouterLink}>
                    Game
                    </ListItem>
                  </List>
                </Grid>
     

       
   
          </Grid>
          <Box className="copy" mt={1} align="center">
          <Typography variant="body2" 
          style={{textAlign: "center", width: "100%", maxWidth: "100%", marginTop: "15px", marginBottom: "15px"}}>
            © 2022 INBT, Inc. All Rights Reserved.</Typography>
        </Box>
        </Container>
       
      </Box>
    </>
  );
}
